import { useHome1, useHome2, useHome3, useHome4 } from "../utils/custom-hooks/image-hooks";

export default {
  data: {
    page: {
      theme: {
        logo: "red",
        type: "dark",
        header: "light"
      },
      title: "Home",
      uri: "/",
      slug: "vivid-consulting-group",
      seo: {
        metaDesc:
          "Vivid helps growth-minded technology leaders go to market and scale their business.",
        opengraphDescription:
          "Vivid helps growth-minded technology leaders go to market and scale their business.",
        },
        opengraphSiteName: "Vivid",
        opengraphTitle: "Home - Vivid",
        opengraphType: "article",
        title: "Home - Vivid",
      flexchildren: [
        {
          __typename: "CtaScroll",
          sections: [
            {
              sectionId: "creating-the-future",
              backgroundGradientLight: "#151515",
              backgroundGradientDark: "#151515",
              backgroundImagePosition: "center",
              backgroundSize: "cover",
              cta: {
                target: "",
                title: "Learn more",
                url: "https://vividcg.com/about/",
              },
              ctaVariant: "dark",
              description: "",
              descriptionColor: "#05334D",
              tag: null,
              tagColor: "#05334D",
              title: "We help growth-minded technology leaders go to market and scale their business.",
              titleColor: "#05334D",
              rightImage: null,
              backgroundImage: {
                altText: "",
                useImage: useHome1,
                localFile: {
                  publicURL: "/static/dc2d198e228664f8968aa3a92f5a87f6/0F0A2232-2-scaled.jpg",
                  extension: "jpg",
                  childImageSharp: {
                    fluid: {
                      base64:
                        "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEA//EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAZdmUkGD/8QAGRABAQADAQAAAAAAAAAAAAAAAwIBERIE/9oACAEBAAEFAkzqZfUVfVeccLDEfJD1P//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EAB0QAAICAQUAAAAAAAAAAAAAAAABESICEiExQYH/2gAIAQEABj8CqzU/UNqYHk+nwVxgdo3P/8QAHBAAAwADAAMAAAAAAAAAAAAAAAERMUFRIaHB/9oACAEBAAE/IYyjHnpP82voPe6DYfUZJdd08jWE/CH/2gAMAwEAAgADAAAAEBAP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBABAQACAwEBAAAAAAAAAAAAAREAMSFBUWHB/9oACAEBAAE/EO7Bxv1rrCKVNHkSA+jcMMWgOuAx66RUUYMcOEIrTh5hoURBeh/c/9k=",
                      aspectRatio: 1.492537313432836,
                      src: "/static/dc2d198e228664f8968aa3a92f5a87f6/a1eb1/0F0A2232-2-scaled.jpg",
                      srcSet:
                        "/static/dc2d198e228664f8968aa3a92f5a87f6/4fa6c/0F0A2232-2-scaled.jpg 200w,\n/static/dc2d198e228664f8968aa3a92f5a87f6/64b17/0F0A2232-2-scaled.jpg 400w,\n/static/dc2d198e228664f8968aa3a92f5a87f6/a1eb1/0F0A2232-2-scaled.jpg 800w,\n/static/dc2d198e228664f8968aa3a92f5a87f6/a6c62/0F0A2232-2-scaled.jpg 1200w,\n/static/dc2d198e228664f8968aa3a92f5a87f6/70ff9/0F0A2232-2-scaled.jpg 1600w,\n/static/dc2d198e228664f8968aa3a92f5a87f6/6ce57/0F0A2232-2-scaled.jpg 2560w",
                      srcWebp:
                        "/static/dc2d198e228664f8968aa3a92f5a87f6/b0751/0F0A2232-2-scaled.webp",
                      srcSetWebp:
                        "/static/dc2d198e228664f8968aa3a92f5a87f6/9e195/0F0A2232-2-scaled.webp 200w,\n/static/dc2d198e228664f8968aa3a92f5a87f6/40a1d/0F0A2232-2-scaled.webp 400w,\n/static/dc2d198e228664f8968aa3a92f5a87f6/b0751/0F0A2232-2-scaled.webp 800w,\n/static/dc2d198e228664f8968aa3a92f5a87f6/a7c53/0F0A2232-2-scaled.webp 1200w,\n/static/dc2d198e228664f8968aa3a92f5a87f6/80926/0F0A2232-2-scaled.webp 1600w,\n/static/dc2d198e228664f8968aa3a92f5a87f6/385a5/0F0A2232-2-scaled.webp 2560w",
                      sizes: "(max-width: 800px) 100vw, 800px",
                      presentationWidth: 800,
                    },
                  },
                },
              },
            },
            {
              sectionId: "future-of-payments",
              backgroundGradientLight: "#a193e2",
              backgroundGradientDark: "#624ebe",
              backgroundImagePosition: "bottom",
              backgroundSize: "contain",
              cta: {
                target: "",
                title: "View the work",
                url: "https://wp.Vivid.net/work/building-the-future-of-payments/",
              },
              ctaVariant: "light",
              description:
                "We’ve partnered with the Diem Association to build and launch a blockchain-based global payment network.",
              descriptionColor: "#FFFFFF",
              tag: "WORK",
              tagColor: "#FFFFFF",
              title: "Building the future of payments",
              titleColor: "#FFFFFF",
              rightImage: {
                altText: "",
                useImage: useHome2,
                localFile: {
                  publicURL: "/static/a132ff53b09ef8d0ae1fa5eb94e988e5/Diem-Header-cutout-2.png",
                  extension: "png",
                  childImageSharp: {
                    fluid: {
                      base64:
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAABYlAAAWJQFJUiTwAAAEgklEQVQ4y0WTfUxTZxTGT9tJoBZHsfYDhALSIoX2tlgB4RYolC+ZsFhRqhajxWIYTqUMRZyKYxRERSAMBkUYEoybskXGzPQP2AR1GhEN2WY2O4aZc5DMORUGbe/ZpWTZk5z/zvmd5837HABaxyrLARHhwdhdhuv5I7hTa/QsP9N582Bzn33r/hO29E07dopFPCnduiQ+931Y4hUHYrEnPQMg8vcD9jIhACMaZDIZuLUp9203cEFzrhe+zc3NR78be4T3n7zCa+NT2DX4I1Z1X3MeON0zptPvsfGF0duj166U0CNM0UoxLOOKAVgkmM3mRaA2SbcAZLld3rdfnJj4DWdm/3K+nvnb+Xp2zjH9fMZpn/oHx5/N4seX7mFi8mmMUZfNy+SGu+viSDGhWgOqqAyGyWRaBPJ8JFBkGGLt3jgMmeSpz02bbVhffW3++sA4/vLzFPXixStqfm6Ocs67nH88e+mYnppx9vb8QEWEW1Aii07yFRGwSvoWMyAgYBHI95WCPmmAtSV5EJKirJcTiJNIyusc/tx0ilRaqL35n2LVsU7q6teDeLX/LvX702nq9q1Jl5o4jEFhSo2XbzgES7KYAoHgf+AW7Vesbboh0KqsfTr1KUxTNzgCRSSqJGZMjGxCid9WFPorcLU0Fa9cvk19+82kSyGvxhBZrGapUA1BYbnMFStWAJypq4VAPwVsjB9gGbQ3ID7ig76UqDrUrTnrCPbXUtGyd3B9bDMmR9VieIgeA3h67G4bpm7e+tUVEnYEpZHrNFz/tRC8Ws/k8/k08GQt1FRVMc9Wf+HRcXoS9hdc+MyY3YFxq2sdHA8CZYE7MT/nE7ra0ZjThanqBjzffoMGTrr8paUYGkFofEThIA5bzxQK6fh8WHkcWpoaoeJgmTs69sc/XZ2wP8Xx0SlHY303tc/chpvTbJhE1GAWWY8auZU6bxumhkaeuESS/ahUEmRgUBhIIzKYXC4XwFp1gv1RYwNJV2VT/ZnRg6WWmd0Fu5zGfAPV2HiWGhkZocYfPsY7w0+o9oZblDmvl+qxDbn6rz90+ASZMFKh1ILHcohUJrzh/pAic4Gm4lDZKO0UT9VYsc5ajWWWEkwgSeRwOE42m+2Qy2ULC1wdna2Oe/fuu44frcSU5GRUELEvRQJhqF6vp7OcsHgcX/ZdXOB6EvKI8LxN+u2lB961HT9yeLSwYNdszoYsjI1ZiyHBQbjc1xe9PL1QJBLgqpDg79lenleWcTx7CgvNty0Wy2B29obclpZWgEOlJW6n2zZvhCuXLv53gkvJdTGKDF3KzvTUlHOpOu2DRE28PVod1SWVhBq4XJ9eb2/On97e3piZkW5PT09rIQgF6Z5tra+BhPgYKN1XzOi2tTH7+y4xF4jFhSaorCinY1UNMmnom1GEQnT5wjkw5uWAQMAPp6FGP5Ewg27lDAz0w458I2RmZgKoVCrg8XhAb4OlbLbbrdGQC8V7ChhFZhMzf1sey1peAodL9kJ8XAwrOyuDIRTwgYaBSqlwv6jsvVJWvnE7IzExEf4FONXwo++dQZkAAAAASUVORK5CYII=",
                      aspectRatio: 1.3245033112582782,
                      src: "/static/a132ff53b09ef8d0ae1fa5eb94e988e5/f9ff4/Diem-Header-cutout-2.png",
                      srcSet:
                        "/static/a132ff53b09ef8d0ae1fa5eb94e988e5/5224a/Diem-Header-cutout-2.png 200w,\n/static/a132ff53b09ef8d0ae1fa5eb94e988e5/d786d/Diem-Header-cutout-2.png 400w,\n/static/a132ff53b09ef8d0ae1fa5eb94e988e5/f9ff4/Diem-Header-cutout-2.png 800w,\n/static/a132ff53b09ef8d0ae1fa5eb94e988e5/73f08/Diem-Header-cutout-2.png 1200w,\n/static/a132ff53b09ef8d0ae1fa5eb94e988e5/ac5f8/Diem-Header-cutout-2.png 1466w",
                      srcWebp:
                        "/static/a132ff53b09ef8d0ae1fa5eb94e988e5/b0751/Diem-Header-cutout-2.webp",
                      srcSetWebp:
                        "/static/a132ff53b09ef8d0ae1fa5eb94e988e5/9e195/Diem-Header-cutout-2.webp 200w,\n/static/a132ff53b09ef8d0ae1fa5eb94e988e5/40a1d/Diem-Header-cutout-2.webp 400w,\n/static/a132ff53b09ef8d0ae1fa5eb94e988e5/b0751/Diem-Header-cutout-2.webp 800w,\n/static/a132ff53b09ef8d0ae1fa5eb94e988e5/a7c53/Diem-Header-cutout-2.webp 1200w,\n/static/a132ff53b09ef8d0ae1fa5eb94e988e5/0d7e6/Diem-Header-cutout-2.webp 1466w",
                      sizes: "(max-width: 800px) 100vw, 800px",
                      presentationWidth: 800,
                    },
                  },
                },
              },
              backgroundImage: {
                altText: "",
                localFile: {
                  publicURL: "/static/a06e50ac15bed4f13d563a15111a41d4/diem-waves.svg",
                  extension: "svg",
                  childImageSharp: null,
                },
              },
            },
            {
              sectionId: "the-api-economy",
              backgroundGradientLight: "#f4f4f4",
              backgroundGradientDark: "#bfbebd",
              backgroundImagePosition: "left",
              backgroundSize: "cover",
              cta: {
                target: "",
                title: "Read the article",
                url: "https://vividcg.com/insights/riding-the-api-wave/",
              },
              ctaVariant: "light",
              description:
                "Global enterprises are quickly realizing that the new economic reality demands efficient interconnectivity, and are racing to build APIs to adapt and survive.",
              descriptionColor: "#ffffff",
              tag: "INSIGHT",
              tagColor: "#0B93E0",
              title: "Riding the API wave",
              titleColor: "#ffffff",
              rightImage: null,
              backgroundImage: {
                altText: "",
                useImage: useHome3,
                localFile: {
                  publicURL:
                    "/static/5707afd4d222c4949e4e25c4307971cc/Vivid_website_desktop_home_insight_riding-the-api-wave-scaled-1.jpg",
                  extension: "jpg",
                  childImageSharp: {
                    fluid: {
                      base64:
                        "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUDBAb/xAAWAQEBAQAAAAAAAAAAAAAAAAADAAH/2gAMAwEAAhADEAAAAU99dMoaQWmX/8QAGRAAAgMBAAAAAAAAAAAAAAAAAQIAESED/9oACAEBAAEFAganJ4rYIh1Ca//EABYRAQEBAAAAAAAAAAAAAAAAAAACEv/aAAgBAwEBPwGmn//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPwGMf//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABkQAAIDAQAAAAAAAAAAAAAAAAABETFBEP/aAAgBAQABPyFrSh6vSO/DoI1T/9oADAMBAAIAAwAAABAcD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEUH/2gAIAQMBAT8QHSFl/8QAFREBAQAAAAAAAAAAAAAAAAAAAAH/2gAIAQIBAT8QLL//xAAaEAADAQEBAQAAAAAAAAAAAAAAAREhMUFR/9oACAEBAAE/ELFjIltV48FqWm3bOmmIdCbhm1j+n//Z",
                      aspectRatio: 1.7094017094017093,
                      src: "/static/5707afd4d222c4949e4e25c4307971cc/a1eb1/Vivid_website_desktop_home_insight_riding-the-api-wave-scaled-1.jpg",
                      srcSet:
                        "/static/5707afd4d222c4949e4e25c4307971cc/4fa6c/Vivid_website_desktop_home_insight_riding-the-api-wave-scaled-1.jpg 200w,\n/static/5707afd4d222c4949e4e25c4307971cc/64b17/Vivid_website_desktop_home_insight_riding-the-api-wave-scaled-1.jpg 400w,\n/static/5707afd4d222c4949e4e25c4307971cc/a1eb1/Vivid_website_desktop_home_insight_riding-the-api-wave-scaled-1.jpg 800w,\n/static/5707afd4d222c4949e4e25c4307971cc/a6c62/Vivid_website_desktop_home_insight_riding-the-api-wave-scaled-1.jpg 1200w,\n/static/5707afd4d222c4949e4e25c4307971cc/70ff9/Vivid_website_desktop_home_insight_riding-the-api-wave-scaled-1.jpg 1600w,\n/static/5707afd4d222c4949e4e25c4307971cc/6ce57/Vivid_website_desktop_home_insight_riding-the-api-wave-scaled-1.jpg 2560w",
                      srcWebp:
                        "/static/5707afd4d222c4949e4e25c4307971cc/b0751/Vivid_website_desktop_home_insight_riding-the-api-wave-scaled-1.webp",
                      srcSetWebp:
                        "/static/5707afd4d222c4949e4e25c4307971cc/9e195/Vivid_website_desktop_home_insight_riding-the-api-wave-scaled-1.webp 200w,\n/static/5707afd4d222c4949e4e25c4307971cc/40a1d/Vivid_website_desktop_home_insight_riding-the-api-wave-scaled-1.webp 400w,\n/static/5707afd4d222c4949e4e25c4307971cc/b0751/Vivid_website_desktop_home_insight_riding-the-api-wave-scaled-1.webp 800w,\n/static/5707afd4d222c4949e4e25c4307971cc/a7c53/Vivid_website_desktop_home_insight_riding-the-api-wave-scaled-1.webp 1200w,\n/static/5707afd4d222c4949e4e25c4307971cc/80926/Vivid_website_desktop_home_insight_riding-the-api-wave-scaled-1.webp 1600w,\n/static/5707afd4d222c4949e4e25c4307971cc/385a5/Vivid_website_desktop_home_insight_riding-the-api-wave-scaled-1.webp 2560w",
                      sizes: "(max-width: 800px) 100vw, 800px",
                      presentationWidth: 800,
                    },
                  },
                },
              },
            },
            {
              sectionId: "monetizing-insights",
              backgroundGradientLight: "#1da1f2",
              backgroundGradientDark: "#005fd1",
              backgroundImagePosition: "center",
              backgroundSize: "contain",
              cta: {
                target: "",
                title: "View the work",
                url: "https://wp.Vivid.net/work/twitter-data/",
              },
              ctaVariant: "light",
              description: "Sourcing, packaging, and monetizing Twitter’s data assets",
              descriptionColor: "#FFFFFF",
              tag: "WORK",
              tagColor: "#FFFFFF",
              title: "Monetizing insights through data alchemy",
              titleColor: "#ffffff",
              rightImage: {
                altText: "",
                useImage: useHome4,
                localFile: {
                  publicURL:
                    "/static/db2d7e1b2935ece41105d0d4f2a02db0/Vivid_success_story_Twitter_ui_design_work_sample_2.png",
                  extension: "png",
                  childImageSharp: {
                    fluid: {
                      base64:
                        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAADxUlEQVQoz1VSW0zTZxz9lG0sslAKFS0DSqECkpYWsIUWaMulWgulWMrFABuzQDu5COgE5sKllRYpdFhGKkw3hqgxZBhuUiCNLSIh7sG5C4MoTBezrfgwM5aRUf7fb7A9eZKTnKeTc3IOeovM2POmTxjyIgUx9voyT7yzn51J3c+SH/SLkCOfZDkKzpEjplIel1MjZ2U3ZFLSPjrBFCv5qORLhLSziM9LQq+Boyj1iJYWIVoUv1N0wQoS822gG28CpW0MFJ2fQn5VEahrG+FkbROoz1bCZ93nIU9V9Ni9BQgA9iRwE183FGnOeKSVVaNInrAzf/A2RE4sbFHv3CMujDYRvzs4xLCOTDSouIRWlUSY6qTuX8wyqEgTfgtwHs3WF77Bl2R6xIsle/veT0WGAjFC75bXe5D5QkTlppiC+kdBNT3hnpwuxZsLYvznXCK+O6DFzbWn8ZUuHZ6b/2b7/owTcvOKHzUYulFYNA/xjsoRTyxB4zVZ6GpZBkJMVZbHTnTkGRplze2qgmcPtO71+RxsaZOBvTcRni7/BJcvW0FdXgGVZ84Rp+oaISY9w8XlJbYmpUv1ghRJB1+Uotltu+uDAjjJFFFVx8PCi/1/VXR9Dv0DH+NXDhq26ai4U3MMlhwjWKrIxaEsLqTKVCA6psCHonkgkSvh5KkKSM7Mg4Q02Wp+HM0zOyYEIS9BQYBm9Oe/P3kEcPohEM1XmvG8Hrntfcrtazot9FQex0IBF/NTZFBYVoFlCjkwotm4pLzUrdHU/FOmrgZx+vEfmGHUfYxgys7KGcUB8qEnf1Q6N6F+cYuo6R2G9lYd6Np7oaW1bVvfqsfsmFgcH8sFgTQdM/nxwE5IxHHiVKwuKSbKPigGcYroSTCN4e3rdwChoPfaAwtvfPfKuvjrtnnRBabZH7/f3tw4+9vL9VsvXOvwwvUSspU5OJnNJuhh4QQ5IJCg0xkEyf8gwRMkuKNiYiCSxVo9FEL3UWYpEDrSeJ2WYRiGLn0L6CYfQ0mf7Y7j+QaasM8VTMze27hrdz4/ksDHJIo/kCgH4G0SeYc+4EX2A3rEYYiK5uzSVVdd5f//D6UKXxYvefBweMRIrDB9NlWWdQ55+yNzb1943xdffThlm56y9PSs6PUXnxqNxlWDwbjW0WFaM+zoS5c61kwm0zOLxbJkt9uvrqwss1BG8wASlDb993qHtWXfdWu3143hEdK1wSHvr8cmA4du3uKMjI5FOJzOENvMTOjk1FTYjqatLC/53p9zUhYWHvjZbFO+ZrOZOj4+7vkvQSi8nHw/xOQAAAAASUVORK5CYII=",
                      aspectRatio: 1.6528925619834711,
                      src: "/static/db2d7e1b2935ece41105d0d4f2a02db0/3e8b8/Vivid_success_story_Twitter_ui_design_work_sample_2.png",
                      srcSet:
                        "/static/db2d7e1b2935ece41105d0d4f2a02db0/5224a/Vivid_success_story_Twitter_ui_design_work_sample_2.png 200w,\n/static/db2d7e1b2935ece41105d0d4f2a02db0/d786d/Vivid_success_story_Twitter_ui_design_work_sample_2.png 400w,\n/static/db2d7e1b2935ece41105d0d4f2a02db0/3e8b8/Vivid_success_story_Twitter_ui_design_work_sample_2.png 749w",
                      srcWebp:
                        "/static/db2d7e1b2935ece41105d0d4f2a02db0/e9aaf/Vivid_success_story_Twitter_ui_design_work_sample_2.webp",
                      srcSetWebp:
                        "/static/db2d7e1b2935ece41105d0d4f2a02db0/9e195/Vivid_success_story_Twitter_ui_design_work_sample_2.webp 200w,\n/static/db2d7e1b2935ece41105d0d4f2a02db0/40a1d/Vivid_success_story_Twitter_ui_design_work_sample_2.webp 400w,\n/static/db2d7e1b2935ece41105d0d4f2a02db0/e9aaf/Vivid_success_story_Twitter_ui_design_work_sample_2.webp 749w",
                      sizes: "(max-width: 749px) 100vw, 749px",
                      presentationWidth: 749,
                    },
                  },
                },
              },
              backgroundImage: null,
            },
          ],
        },
      ],
    },
  },
  pageContext: {
    id: "cG9zdDoxMA==",
    databaseId: 10,
    nextPage: 14,
  },
};
